"use client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";
import "@/plugins/axios";
import Headers from "@/components/Header";
import Footer from "@/components/Footer";
import { MarketContext } from "@/app/Context";
import { Wallet } from "@/components/unisat/ConnectWallet";
import { CoinsStatus } from "@/api/query/getCoinsStatus";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function Registry({ children }: { children: ReactNode }) {
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [coinsStatus, setCoinsStatus] = useState<CoinsStatus | null>(null);
  const [connectedWallet, setConnectedWallet] = useState<
    Wallet["types"] | null
  >(null);
  useEffect(() => {
    if (localStorage.getItem("connected_wallet")) {
      setConnectedWallet(
        localStorage.getItem("connected_wallet") as Wallet["types"],
      );
    }
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <MarketContext.Provider
        value={{
          wallet,
          connectedWallet,
          coinsStatus,
          setWallet,
          setConnectedWallet,
          setCoinsStatus,
        }}
      >
        <div
          className="min-h-screen flex flex-col overflow-hidden"
          onClick={async () => {
            wallet?.closeMenu();
          }}
        >
          <Headers />
          <div className="flex-1">{children}</div>
          <Footer />
        </div>
      </MarketContext.Provider>
    </QueryClientProvider>
  );
}
