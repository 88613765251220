import axios from "axios";
import toast from "react-hot-toast";

axios.interceptors.request.use((request) => {
  return request;
});

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response;
    } else {
      return Promise.reject({
        code: response.status,
        message: response.statusText,
        data: null,
      });
    }
  },
  (e) => {
    if (e.config.url.includes("/api/trpc")) {
      if (Array.isArray(e.response.data)) {
        if (e.response.data[0].error) {
          toast.error(e.response.data[0].error.json.message, {
            id: "error",
          });
          return Promise.reject({
            code: e.response.status,
            msg: e.response.data,
            data: null,
          });
        }
      } else {
        toast.error(e.response.data.error.json.message, {
          id: "error",
        });
        return Promise.reject({
          code: e.response.status,
          msg: e.response.data.error.json.message,
          data: null,
        });
      }
    }
    if (e.response.status === 400) {
      if (typeof e.response.data === "string") {
        toast.error(e.response.data || "Interface error", {
          id: "error",
        });
      }
    }
    if (e.response.status === 500) {
      toast.error("Interface error", {
        id: "error",
      });
    }
    return Promise.reject({
      code: e.response.status,
      msg: e.response.data,
      data: null,
    });
  },
);
