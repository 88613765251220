import { useContext, useEffect, useMemo, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ConnectWallet, Search } from "@/components";
import { useChainFeeRate, useCoinsStatus } from "@/api/query";
import { MarketContext } from "@/app/Context";
import { logoIcon, fuelIcon } from "@/assets";
const Header = () => {
  const path = usePathname();
  const { setCoinsStatus } = useContext(MarketContext);
  const [showDrawer, setShowDrawer] = useState(false);
  const menuList = useMemo(() => {
    return [
      {
        name: "Marketplace",
        href: "/marketplace/atomicals",
      },
      {
        name: "Explore",
        href: "/explore",
        disabled: true,
      },
    ];
  }, []);
  const { data } = useChainFeeRate();
  const { data: coinsStatus } = useCoinsStatus();
  useEffect(() => {
    if (coinsStatus?.data) {
      setCoinsStatus(coinsStatus.data);
    }
  }, [coinsStatus]);
  useEffect(() => {
    if (showDrawer) {
      document.body.style.height = "100vh";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "";
      document.body.style.overflow = "";
    }
  }, [showDrawer]);
  return (
    <div className="w-full h-[60px]">
      <div className="h-full px-5 md:px-10 mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <Link href="/">
            <Image
              className="md:w-[99px] w-[80px] md:mr-[27px]"
              priority
              src={logoIcon}
              alt=""
            />
          </Link>
          <div className="text-sm chill text-[#7F7D7A] leading-none gap-6 hidden md:flex">
            {menuList.map((item) => (
              <Link
                key={item.href}
                href={item.disabled ? "" : item.href}
                className={`${path.includes(item.href) ? "text-white" : ""} ${
                  item.disabled ? "disabled" : ""
                }`}
              >
                {item.disabled ? (
                  <div
                    className="tooltip tooltip-bottom"
                    data-tip="Coming soon"
                  >
                    {item.name}
                  </div>
                ) : (
                  item.name
                )}
              </Link>
            ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <Search />
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-[6px] text-white chill text-sm">
            <Image height={14} src={fuelIcon} alt="" />
            {data?.hourFee ? <span>{data?.halfHourFee}</span> : null}
            <span className="text-[#FFAF5D]">sat/vB</span>
          </div>
          <div className="hidden lg:block">
            <ConnectWallet />
          </div>
          <label className="btn btn-circle w-8 h-8 min-h-0 swap swap-rotate lg:hidden sm:inline-grid relative z-[999]">
            <input
              type="checkbox"
              checked={showDrawer}
              onChange={(e) => {
                setShowDrawer(e.target.checked);
              }}
            />
            <svg
              className="swap-off fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 512 512"
            >
              <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
            </svg>
            <svg
              className="swap-on fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 512 512"
            >
              <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
            </svg>
          </label>
        </div>
      </div>
      <div className="drawer">
        <input
          id="head-menu"
          type="checkbox"
          checked={showDrawer}
          onChange={() => {}}
          className="drawer-toggle"
        />
        <div className="drawer-side z-50">
          <label
            htmlFor="head-menu"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu p-4 pt-16 w-full min-h-full bg-[#252524]">
            {menuList.map((item) => (
              <Link
                key={item.href}
                href={item.disabled ? "" : item.href}
                onClick={() => {
                  setShowDrawer(false);
                }}
                className={`${
                  path.includes(item.href) ? "text-[#AAFF5D]" : ""
                } py-2 px-4 border-b border-[rgba(240,236,230,0.05)] hover:bg-[rgba(255,255,255,0.05)] cursor-pointer ${
                  item.disabled ? "disabled" : ""
                }`}
              >
                {item.name}
              </Link>
            ))}
            <div className="mt-5">
              <ConnectWallet
                onClose={() => {
                  setShowDrawer(false);
                }}
              />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Header;
