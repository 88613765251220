import { useContext, useEffect, useRef } from "react";
import Image from "next/image";
// import Cookies from "js-cookie";
import {
  logoIcon,
  twitterIcon,
  // discordIcon,
  bookIcon,
  buckIcon,
} from "@/assets";
import { useUsdPrice } from "@/api/query";
import { formatPrice } from "@/plugins/utils";
import { useGetAccounts } from "@/hooks";
import { MarketContext } from "@/app/Context";
const Footer = () => {
  const { accounts } = useGetAccounts();
  const { data: usdPrice = 0 } = useUsdPrice();
  const { wallet } = useContext(MarketContext);
  const accountsRef = useRef<string | null>(null);

  const handleSign = () => {
    if (document.visibilityState !== "visible") return; // 只有在可见状态时执行签名操作
    if (accountsRef.current === null) return;
    if (accounts.length > 0 && accounts[0] !== accountsRef.current) {
      accountsRef.current = accounts[0];
      localStorage.setItem("address", accounts[0]);
      wallet?.sign(accounts[0]);
    }
  };

  useEffect(() => {
    handleSign();
  }, [accounts]);

  useEffect(() => {
    if (document.visibilityState !== "visible") return; // 只有在可见状态时更新 accountsRef
    accountsRef.current = accounts[0] || localStorage.getItem("address") || "";
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        handleSign();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [accounts]);
  return (
    <div
      className="w-full h-[96px] flex justify-center"
      style={{
        boxShadow: "0 -1px 0 0 rgba(240,236,230,0.1)",
      }}
    >
      <div className="w-full px-5 md:px-10  mx-auto flex justify-between items-center">
        <div className="block md:flex items-center">
          <Image width={116} src={logoIcon} alt="" />
          <div className="flex gap-3 mt-2 md:ml-[28px] md:mt-0">
            <a
              href="https://twitter.com/unibitmarket"
              target="_blank"
              rel="noreferrer"
            >
              <Image width={32} src={twitterIcon} alt="" />
            </a>
            {/* <a href="">
              <Image width={32} src={discordIcon} alt="" />
            </a> */}
            <a
              href="https://unibitbtc.gitbook.io/unibit-docs/"
              target="_blank"
              rel="noreferrer"
            >
              <Image width={32} src={bookIcon} alt="" />
            </a>
          </div>
        </div>
        <div className="text-[#FFAF5D] text-base chill flex gap-2 items-center">
          <Image width={12} src={buckIcon} alt="" />$ {formatPrice(usdPrice)}
        </div>
      </div>
    </div>
  );
};
export default Footer;
